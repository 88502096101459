<template>
    <div v-if="questionnaire.csat.question" class="questionnaire relative md:static pb-12 md:pb-6 select-none flex flex-col items-center pt-4 md:pt-0 md:justify-center">
        <div class="wrapper">
            <div class="title text-grey-550 font-bold leading-none mb-5 md:mb-6 text-center md:text-left">{{ questionnaire.csat.company_name }}</div>
            <div class="title text-black font-bold leading-none mb-5 md:mb-6 text-center md:text-left">{{ questionnaire.csat.question }}</div>
            <div v-if="ratings.items" class="ratings flex flex-col md:flex-row">
                <div v-for="(rating, index) in ratings.items" :key="index" class="relative">
                    <div class="flex flex-col items-center" :class="{'md:ml-6':index!==0}">
                        <div class="rating flex items-center justify-center relative cursor-pointer mb-2 md:mb-6 w-full md:w-auto"
                                :class="{['rating-'+rating.rating]:rating.rating,
                                        'negative':activeItem === rating.rating && (rating.rating === '1' || rating.rating === '2'),
                                        'neutral':activeItem === rating.rating && rating.rating === '3',
                                        'positive':activeItem === rating.rating && (rating.rating === '4' || rating.rating === '5')}"
                                @click="updateRating(rating.rating)">
                            <div class="flex items-center w-41 md:w-full">
                                <span v-if="activeItem === rating.rating" class="absolute flex justify-center items-center bg-white checkmark">
                                    <i class="fal fa-check fa-lg"></i>
                                </span>
                                <span class="emoji">{{rating.emoji}}</span>
                                <span class="rating-description ml-4 md:hidden">{{rating.description}}</span>
                            </div>
                        </div>
                        <span class="rating-description hidden md:block">{{rating.description}}</span>
                    </div>
                </div>
            </div>
            <div class="subtitle mt-11 md:mt-16 md:pt-6 mb-4">{{ questionnaire.csat.follow_up_question }}</div>
            <div class="description bg-white">
                <textarea v-model="questionnaire.csat.remark" class="focus:outline-none w-full" autofocus></textarea>
            </div>
            <button class="btn text-white focus:outline-none mt-5 md:mt-4 mb-16 md:mb-10 md:mb-0 w-full md:w-auto" @click="updateRating(activeItem, true)">Send </button>
        </div>
        <powered-by v-if="questionnaire.csat.branding_enabled"></powered-by>
    </div>
</template>

<script>
    /* global axios */
    import PoweredBy from "./PoweredBy";

    export default {
        components: {PoweredBy},
        data() {
            return {
                questionnaire: {
                    csat: {
                        question: '',
                        follow_up_question: '',
                        remark: '',
                        company_name: '',
                    },
                },
                ratings: {
                    items: [{
                        rating: '1',
                        emoji: '😞',
                        description: ''
                    },
                    {
                        rating: '2',
                        emoji: '🙁',
                        description: ''
                    },
                    {
                        rating: '3',
                        emoji: '😐',
                        description: ''
                    },
                    {
                        rating: '4',
                        emoji: '🙂',
                        description: ''
                    },
                    {
                        rating: '5',
                        emoji: '😁',
                        description: ''
                    }]
                },
                activeItem: 0,
            }
        },
        mounted() {
            const params = new URLSearchParams(window.location.search)
 
            // empty url, redirect to Trengo homepage
            if (!params.get('uuid')) {
                window.location.href = 'https://trengo.com';
            }

            axios.get('public/' + params.get('uuid')).then(res => {
                this.questionnaire = res.data;

                // the questionnaire has already been submitted
                if (res.data.submitted_at) {
                    this.$router.push('/thank-you/'+params.get('uuid'));
                }
            })

            this.activeItem = params.get('rating');

            axios.put('public/' + params.get('uuid'), {rating: params.get('rating'), is_submitted: false});
        },
        methods: {
            updateRating(rating, submit = false) {
                this.activeItem = rating;

                const params = new URLSearchParams(window.location.search)
                axios.put('public/' + params.get('uuid'), {
                    rating: rating,
                    remark: submit && this.questionnaire.csat.remark ? this.$root.stripHtml(this.questionnaire.csat.remark) : '',
                    is_submitted: submit
                }).then(() => {
                    if (submit) {
                        this.$router.push('/thank-you/' + params.get('uuid'));
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .questionnaire {
        .wrapper {
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.02), 0 13px 56px rgba(0, 0, 0, 0.03);
            padding: 40px 160px;
            border-radius: 12px;
        }
        .title {
            &.text-grey-550 {
                color: #838389;
            }
            font-size: 40px;
        }
        .rating-description {
            font-weight: 600;
        }
        .ratings {
            .rating {
                border: 1px solid var(--color-grey-400);
                padding: 24px 35px;
                border-radius: 12px;
                .emoji {
                    font-size: 44px;
                }
                .checkmark {
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    width: 32px;
                    height: 32px;
                }
            }
            .rating-1, .rating-2, .rating-3, .rating-4, .rating-5 {
                transition: all ease-out .2s;
                &:hover {
                    transition: all ease-in .2s;
                    margin-top: -3px;
                }
            }
            .rating-1, .rating-2 {
                &:hover {
                    background: var(--color-error-100);
                    border-color: var(--color-error-500);
                    box-shadow: 0 60px 80px rgba(232, 55, 76, 0.04), 0 18.0882px 24.1177px rgba(232, 55, 76, 0.0260636), 0 7.51293px 10.0172px rgba(232, 55, 76, 0.02), 0 2.71728px 3.62304px rgba(232, 55, 76, 0.0139364);
                }
            }
            .rating-3 {
                &:hover {
                    background: var(--color-sun-100);
                    border-color: var(--color-sun-500);
                    box-shadow: 0 60px 80px rgba(241, 183, 73, 0.04), 0 18.0882px 24.1177px rgba(241, 183, 73, 0.0260636), 0 7.51293px 10.0172px rgba(241, 183, 73, 0.02), 0 2.71728px 3.62304px rgba(241, 183, 73, 0.0139364);
                }
            }
            .rating-4, .rating-5 {
                &:hover {
                    background: var(--color-success-100);
                    border-color: var(--color-success-500);
                    box-shadow: 0 60px 80px rgba(79, 220, 139, 0.04), 0 18.0882px 24.1177px rgba(79, 220, 139, 0.0260636), 0 7.51293px 10.0172px rgba(79, 220, 139, 0.02), 0 2.71728px 3.62304px rgba(79, 220, 139, 0.0139364);
                }
            }
            span {
                font-size: 14px;
                color: var(--color-grey-550);
            }
            .negative {
                background: var(--color-error-100);
                border-color: var(--color-error-500);
                i {
                    color: var(--color-error-900);
                }
                .rating-description {
                    color: var(--color-grey-900);
                }
            }
            .neutral {
                background: var(--color-sun-100);
                border-color: var(--color-sun-500);
                i {
                    color: var(--color-sun-900);
                }
                .rating-description {
                    color: var(--color-grey-900);
                }
            }
            .positive {
                background: var(--color-success-100);
                border-color: var(--color-success-500);
                i {
                    color: var(--color-success-900);
                }
                .rating-description {
                    color: var(--color-grey-900);
                }
            }
            .active-description {
                color: var(--color-grey-900);
            }
        }
        .subtitle {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: var(--color-grey-900);
        }
        .description textarea {
            height: 188px;
            border: 1px solid var(--color-grey-400);
            box-sizing: border-box;
            border-radius: 12px;
            padding: 10px 12px;
            resize: none;
            font-weight: 500;
            font-size: 18px;
        }
        .btn {
            padding: 18px 55px;
            font-weight: 600;
            font-size: 18px;
            border-radius: 96px;
            background: var(--color-grey-900);
            &:hover {
                transition: all linear .2s;
                box-shadow: 0 8px 12px rgba(0, 0, 0, 0.12);
            }
            &:active {
                background: var(--color-grey-700);
                box-shadow: none;
            }
        }
        .description textarea:focus {
            border-color: var(--color-leaf-500);
        }
    }
    @media(max-width: 767px) {
        .questionnaire {
            .wrapper {
                width: calc(100vw - 30px);
                box-shadow: none;
                padding: 0;
                border-radius: 0;
                margin: 0 auto;
            }
            .title {
                font-size: 24px;
                line-height: 28px;
            }
            .ratings {
                .rating {
                    padding: 5px 0px;
                    border-radius: 96px;
                    .checkmark {
                        top: auto;
                        left: 7%;
                        transform: none;
                    }
                    .emoji {
                        font-size: 32px;
                    }
                }
                .rating-1, .rating-2, .rating-3, .rating-4, .rating-5 {
                    &:hover {
                        transition: none;
                        margin-top: 0;
                    }
                }
                .negative {
                    span {
                        color: var(--color-error-900);
                    }
                }
                .neutral {
                    span {
                        color: var(--color-sun-900);
                    }
                }
                .positive {
                    span {
                        color: var(--color-success-900);
                    }
                }
            }
            .subtitle {
                font-size: 18px;
                line-height: 24px;
            }
            .description textarea {
                height: 120px;
            }
        }
    }
</style>
