<template>
    <router-view />
</template>

<script>
    import Util from './Util';

    export default {
        name: 'App',

        Components: {Util}
    }
</script>

<style>
    html, body {
         height: 100%;
    }
    body {
         font-family: Inter, Arial, sans-serif !important;
         -webkit-font-smoothing: antialiased;
         text-rendering: optimizeLegibility;
    }

    @media(max-width: 768px) {
         html, body {
              height: auto;
         }
    }
</style>
