import VueRouter from 'vue-router';
let routes = [{
    path: '/',
    props: true,
    component: require('./components/Questionnaire').default,
},{
    path: '/thank-you/:uuid',
    props: true,
    component: require('./components/ThankYou').default,
}];

let router = new VueRouter({
    routes,
    mode: 'history',
});
export default router;