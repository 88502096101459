export default {
    data() {
        return {
            test: 'test'
        }
    },

    methods: {
        isMobileDevice() {
            return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || this.inIframe() && document.body.clientWidth < 5000;
        },

        stripHtml(html) {
            let dom = (new DOMParser()).parseFromString(html, 'text/html');
            return dom.documentElement.innerText;
        },

        inIframe() {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        },
    }
}
