import Axios from 'axios';
import Vue from 'vue';
import App from './App.vue';
import './main.scss';
import {VTooltip} from 'v-tooltip';
import VueRouter from 'vue-router';
import router from './routes';
import Util from './Util';

// require('./uikit');

Vue.config.productionTip = false;
Vue.use(VueRouter);

Axios.defaults.baseURL = process.env.VUE_APP_TRENGO_API;

Vue.directive('tooltip', VTooltip);

window.axios = Axios;

new Vue({
  render: h => h(App),
  router,
  mixins: [Util],
}).$mount('#app')