<template>
    <div v-if="questionnaire.csat.thank_you_message" class="thank-you h-full flex flex-col justify-center items-center pb-6">
        <span class="circle flex justify-center items-center">
            <i class="far fa-check text-white"></i>
        </span>
        <span class="title text-black md:mt-8 mt-6 md:mb-12 mb-10">{{ questionnaire.csat.thank_you_message }}</span>
        <a v-if="questionnaire.csat.enable_button && questionnaire.csat.button_url && questionnaire.csat.button_text_thank_you" :href="questionnaire.csat.button_url" class="btn text-black focus:outline-none">{{ questionnaire.csat.button_text_thank_you }}</a>
        <powered-by v-if="questionnaire.csat.branding_enabled"></powered-by>
    </div>
</template>

<script>
    /* global axios */
    import PoweredBy from "./PoweredBy";

    export default {
        components: {PoweredBy},
        data() {
            return {
                questionnaire: {
                    csat: {
                        thank_you_message: '',
                        button_text_thank_you: '',
                        button_url: '#',
                        enable_button: false
                    },
                },
            };
        },
        mounted() {
            let uuid = location.pathname.split('/').pop();
            
            axios.get('public/' + uuid).then(res => {
                this.questionnaire = res.data;
            })
        },
    }
</script>

<style lang="scss" scoped>
    .thank-you {
        .circle {
            border-radius: 50%;
            width: 168px;
            height: 168px;
            background: var(--color-leaf-400);
            box-shadow: 0 100px 80px rgba(73, 178, 161, 0.07), 0 42px 33px rgba(73, 178, 161, 0.05), 0 22.3px 18px rgba(73, 178, 161, 0.04), 0 13px 10px rgba(73, 178, 161, 0.04), 0 6.7px 5.3px rgba(73, 178, 161, 0.03), 0 2.8px 2.2px rgba(73, 178, 161, 0.02);
            .fa-check {
                font-size: 5em;
            }
        }
        .title {
            font-weight: bold;
            line-height: 52px;
            font-size: 40px;
        }
        .btn {
            padding: 17px 45px;
            background: var(--color-white);
            border: 1px solid var(--color-grey-350);
            font-weight: 600;
            font-size: 18px;
            border-radius: 96px;
            &:hover {
                transition: all linear .2s;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03), 0 2px 5px rgba(0, 0, 0, 0.11);
            }
            &:active {
                box-shadow: none;
            }
        }
        .powered-by {
            font-weight: 500;
            font-size: 14px;
            color: var(--color-galaxy-600);
        }
    }
    @media(max-width: 768px) {
        .thank-you {
            height: 100vh;
            .circle {
                width: 96px;
                height: 96px;
                box-shadow: 0 100px 80px rgba(73, 178, 161, 0.05), 0 22.3363px 17.8px rgba(73, 178, 161, 0.03), 0 6.6501px 5.3px rgba(73, 178, 161, 0.02);
                .fa-check {
                    font-size: 3em;
                }
            }
            .title {
                font-size: 24px;
                line-height: 28px;
            }
            .btn {
                padding: 11px 30px;
                font-size: 16px;
            }
        }
    }
</style>
